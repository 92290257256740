[data-cyid='cy-range-slider-component'] {
    .typography {
        position: absolute;
        display: flex;
        width: calc(100% - 45px);
        bottom: 23%;
        padding: 10px;
    }

    .range-slider {
        .MuiSlider-thumb {
            height: 24px;
            width: 24px;
            border: none;
            background: transparent;
            box-shadow: none;

            &::before {
                box-shadow: none !important;
            }

            .thumb-icon {
                height: 100%;
                width: 100%;
            }
        }

        .MuiSlider-rail {
            border-radius: 6px;
            border: 1px solid #A0BEFF;
            background: var(--Light-blue, #ECF2FF);
            height: 43px;
        }

        .MuiSlider-track {
            display: block;
            background: transparent;
            border: none;
        }
    }
}