@import 'styles/extends.scss';

.page-header-component {
    &-top {
        display: flex;
        align-items: flex-end;

        &.with-btn {
            justify-content: space-between;
        }

        &__head {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .sync {
                display: flex;
                align-items: center;
                color: #767575;
                gap: 2px;
                justify-content: flex-start;
                font-size: 0.875rem;
                cursor: default;
            }
        }
    }

    .tile-component {
        margin-left: 15px;
    }

    .page-header-label {
        @extend %label-with-tooltip
    }

    &-controls {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &.single {
            justify-content: flex-end;
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;
        }
    }

    h1 {
        font-weight: 400;
        font-size: 18px !important;
        display: flex;
        align-items: center;
        gap: 10px;

        &.loading {
            @extend %light-ghost-animation;
            width: 100px;
            height: 32px;
            border-radius: 8px;
        }
    }
}