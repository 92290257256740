.yaml-top {
    height: 200px !important;
}

.content__copy-container {
    background: #F8F8F8;
    border: 1px solid #CBC4C4;
    height: 100%;

    &__copy {
        border-bottom: 1px solid #CBC4C4;
        padding: 0px 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .with-icon {
        justify-content: space-between;
    }

    pre,
    code {
        font-family: monospace;
    }


    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        max-width: 630px;
        padding: 15px;
        display: inline-flex;
        flex-direction: column;
    }
}