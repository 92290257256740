.columns-dropdown {
    .select-column-dropdown {
        display: flex;
        padding: 9px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 71px;
        background: var(--color-dark-blue);
        max-width: max-content;
        flex-direction: row-reverse;
        color: #FFF;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
        height: 36.88px;

        .MuiSelect-select {
            padding: 0 !important;
        }

        fieldset {
            border: none !important;
            outline: none !important;
        }
    }

    &__menu-list {
        .MuiMenu-paper {
            max-height: 400px !important;
            overflow: auto;
        }
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: row-reverse;
        color: #FFF;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;

        svg {
            path {
                fill: none !important;
            }
        }
    }
}