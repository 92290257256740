.admin-dashboard-component {
    height: 100%;
    width: 100%;

    &__header {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-bottom: 10px;
    }


    &__flex-container {
        display: flex;
        gap: 20px;

        &__left {
            flex: 40%;
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 32px 26px;
            flex-direction: column;
            gap: 15px;

            h1 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }
        }

        &__right {
            flex: 50%;
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 32px 26px;
            flex-direction: column;
            gap: 15px;

            h1 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }

            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }
        }
    }

    &__main-stats {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex: 40% 1;



        &__container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: stretch;


            &-content {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0 !important;
                background: var(--Base-White, #FFF);
                box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
                padding: 18px 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                // height: 100px;
                width: 210px;
                align-items: center;
                justify-content: center;

                &__title {
                    color: var(--Base-Black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24.929px;
                    text-transform: uppercase;
                    letter-spacing: -0.345px;
                    align-items: stretch;
                    gap: 5px;
                }

                &__value {
                    color: var(--Base-Black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 30.682px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30.682px;
                    letter-spacing: -0.614px;
                }
            }

        }

        .stats-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            width: 100%;

        }

        &__client-charts {
            height: 450px;
            width: 50%;
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0 !important;
            background: var(--Base-White, #FFF);
            box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
            padding: 20px;

            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter, var(--inter);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24.929px;
                text-transform: uppercase;
                letter-spacing: -0.345px;

            }
        }

        &__charts {
            height: 400px;
            width: 700px;
        }
    }

    &__revenue {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: flex-start;
        gap: 10px;



        &__container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: stretch;


            &-content {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0 !important;
                background: var(--Base-White, #FFF);
                box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
                padding: 18px 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                // height: 100px;
                width: 210px;
                align-items: center;
                justify-content: center;

                &__title {
                    color: var(--Base-Black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24.929px;
                    text-transform: uppercase;
                    letter-spacing: -0.345px;
                }

                &__value {
                    color: var(--Base-Black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 30.682px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30.682px;
                    letter-spacing: -0.614px;
                }
            }

        }

        &__charts {
            height: 400px;
            width: 700px;
        }
    }

    .client-list-component {
        flex: 50%;

        .client-data {
            width: 100%;
            height: fit-content;
            max-height: 600px;
            overflow: auto;
        }

        .resources-list {
            .blue {
                color: var(--color-dark-blue)
            }

            .green {
                color: var(--color-dark-green);
            }

            .true-condition {
                background-color: var(--color-dark-green);
                color: white;
                padding: 10px;
                border: 2px solid var(--color-dark-green);
                border-radius: 5px;
                text-align: center;
                height: 20px;
                width: 20px;
            }

            .false-condition {
                background-color: var(--color-red);
                color: white;
                padding: 10px;
                border: 2px solid var(--color-red);
                border-radius: 5px;
                text-align: center;
                height: 20px;
                width: 20px;
            }

            .hollow-blue-button {
                color: var(--color-light-blue);
                background: white;
                border: 1px solid var(--color-light-blue);
                height: 40px;
                border-radius: 4px;
            }

            .keys {
                max-width: 300px;
                text-wrap: wrap;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .labels-wrapper,
            .labels_wrapper {
                display: flex;
                gap: 10px
            }

            .labels {
                background-color: var(--color-dark-green);
                color: var(--color-white);
                padding: 9px 11px;
                border-radius: 4px;
            }

        }

        &-content {
            // border-radius: 12px;
            // border: 0.06rem solid #CBD5E0;
            // background: var(--Base-White, #FFF);
            // box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            // display: flex;
            padding: 32px 26px;
            // flex-direction: column;
            // min-height: 730px;
            // gap: 20px;

            &__header {
                display: flex;
                justify-content: space-between;

                .button-component {
                    width: 260px;
                }

                .text-input-component {
                    width: 35%;
                    margin-left: auto;
                }

                &__button-group {
                    display: inline-flex;

                    .drain_node {
                        font-family: var(--inter);
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
                        width: fit-content;
                    }

                    .select_all {
                        margin-left: 5px;

                        .MuiFormControlLabel-label {
                            font-family: var(--inter);
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: 19px;
                            letter-spacing: 0em;
                            text-align: left;
                        }

                        svg {
                            color: var(--color-dark-blue)
                        }
                    }
                }
            }

            &__wrapper {
                overflow: hidden;
                display: flex;
                max-height: 100%;
                height: 100%;

                .inventories-page-data {
                    height: 100%;
                    max-height: 100%;
                    flex: 1;
                    max-width: 100%;

                    .table-component {
                        display: flex;
                        flex-direction: column;
                        height: calc(100vh - 420px);

                        div[cy-pagination-stack-table-component] {
                            flex-shrink: 0;
                        }
                    }

                    &-wrapper {
                        width: 100%;
                        flex: 1;
                        overflow-y: auto;
                    }


                    &.collapsed {
                        max-width: calc(100% - 290px);
                    }
                }
            }

        }
    }
}