%ghost-animation {
    animation-name: ghost-loading;
    animation-duration: 1s;
    animation-iteration-count: 100;
    animation-timing-function: ease-in-out;
}

%light-ghost-animation {
    animation-name: light-ghost-loading;
    animation-duration: 1s;
    animation-iteration-count: 100;
    animation-timing-function: ease-in-out;
}

%text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%label-with-tooltip {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

%table-cells {
    font-family: var(--inter);
    display: table-cell;
    vertical-align: inherit;
    border: 1px solid var(--color-21) !important;
    text-align: left;
    padding: 16px;
}