 .connect-cloud-button {
     display: inline-flex;
     padding: 12px 16px;
     justify-content: center;
     align-items: center;
     gap: 4px;
     border-radius: 6px;
     background: #4285F4;
     color: #FFF;
     text-align: center;
     font-family: Inter, var(--inter);
     font-size: 1rem;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     height: 43px;
 }