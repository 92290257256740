.time-range-filter {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-top {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .MuiFormControlLabel-label {
            color: var(--Medium-Grey, var(--Base-Black, #767575));
            text-align: center;
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
        }
    }

    &-main {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 24px;
        border-radius: 4px;
        background: #F8F8F8;

        &-item {
            color: var(--Base-Black, #000);
            font-family: Inter;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            cursor: pointer;

            &.active {
                display: flex;
                padding: 6px 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 4px;
                border: 1px solid rgba(66, 133, 244, 0.50);
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            }
        }
    }

    &-button {
        &-span {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

}

.date-picker-components {
    padding: 20px;
    gap: 10px;
    display: flex;

}

.MuiPickersLayout-root {
    padding: 20px;

}