.dropdown-menu-component {
    width: 100%;
    border-radius: 11px;
    overflow: auto;
    max-height: 224px;
    background-color: var(--color-white);
    padding: 5px;
    font-size: 0.875rem;

    &-item {
        cursor: pointer;
        text-align: left;
        color: var(--color-dark-grey);
        line-height: 19px;
        padding: 10px;
        border-radius: 11px;

        &.active,
        &:hover {
            background-color: var(--color-3);
        }

        &.selected {
            background-color: var(--color-2);
        }
    }
}
