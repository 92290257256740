 .report-dropdown {
     display: flex;
     padding: 9px 16px;
     justify-content: center;
     align-items: center;
     gap: 10px;
     border-radius: 71px;
     background: var(--color-dark-blue);
     color: #FFF;
     font-size: 0.875rem;
     font-style: normal;
     font-weight: 500;
     line-height: 135%;

     .placeholder {
         display: flex;
         align-items: center;
         gap: 10px;

         svg {
             font-size: medium;

             path {
                 stroke: inherit;
                 outline: inherit;
             }
         }
     }

     svg {
         path {
             stroke: #FFF;
             outline: #FFF;
         }
     }
 }