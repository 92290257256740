.tile-component {
    display: inline-flex;
    line-height: 21px;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    padding: 0 11px;

    &.blue {
        color: var(--color-white);
        background-color: var(--color-light-blue);
    }

    &.light-blue {
        color: var(--color-light-black);
        background-color: var(--color-27);
    }

    &.yellow {
        color: var(--color-light-black);
        background-color: var(--color-yellow);
    }

    &.warning {
        color: var(--color-light-black);
        background-color: var(--color-36);
    }

    &.green {
        color: var(--color-light-black);
        background-color: var(--color-light-green);
    }

    &.red {
        color: var(--color-white);
        background-color: var(--color-red);
    }

    &.dark-green {
        color: var(--color-white);
        background-color: var(--color-dark-green);
    }

    &.small {
        line-height: 15px;
        font-size: 0.75rem;
    }
}