.ticket-labels-select {
    &__control {
        min-height: 56px !important;

        &:hover:not(&--is-focused) {
            border-color: rgba(0, 0, 0, 0.87) !important;
        }

        &--is-focused {
            border-color: #1565c0 !important;
            border-width: 1px !important;
            box-shadow: none !important;

        }
    }

    &__multi-value {
        color: #1565c0 !important;
        -webkit-text-fill-color: #1565c0 !important;
        background-color: #ECF2FF !important;
        height: 25px !important;
        display: flex !important;
        border: 1px solid #1565c0;
        align-items: center !important;
        border-radius: 16px !important;
    }

}