@import 'styles/extends.scss';

.apply-projections-component-advance {
    color: var(--color-light-black);
    height: 110px;
    background: white;
    // flex: 1;


    &.ghost {
        @extend %light-ghost-animation;
        border-radius: 12px;
    }

    &-info {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 10px;

        &-stats {
            display: flex;
            flex: 1 1;
            align-items: center;
            border-radius: 12px;
            border: 1px solid #A0EBC7;
            background: #E3FFEF;
            box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
            height: 110px;
        }

        &-savings {
            height: 100%;
            border-left: 1px solid var(--color-21);
            display: flex;
            align-items: center;
            padding: 20px;
            gap: 20px;
            border-radius: 12px;
            border: 1px solid #A0BEFF;
            background: var(--Light-blue, #ECF2FF);
            box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
        }
    }

    &-stats-item {
        flex: 1;
        padding: 0px 40px;

        &.criteria-dropdown {
            padding: 0px !important;
        }

        &:not(:first-child) {
            border-left: 1px solid var(--color-21);
        }

        &-value {
            font-family: GT Walsheim Pro;
            font-size: 1.5rem !important;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;


            &.blue {
                color: var(--color-light-blue);
            }

            &.red {
                color: var(--color-red);
            }

            &.green {
                color: var(--color-green);
            }
        }

        &-text {
            display: inline-flex;
            align-items: flex-end;
            gap: 20px;
            text-transform: uppercase;
            font-family: GT Walsheim Pro;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-align: left;

            &.bold {
                font-weight: 700;
            }
        }
    }

    .dropdown-component {
        color: var(--color-yellow);
    }

    .projections-label {
        @extend %label-with-tooltip;
        display: inline-flex;
        align-items: flex-end;
        gap: 20px;
        text-transform: uppercase;
        font-family: GT Walsheim Pro;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: left;
    }
}