.loading-spinner-component {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1400;

    .loader {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            border: 6px solid var(--color-2);
            border-top-color: var(--color-3);
            animation: spin 0.8s infinite linear;
        }

        &::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
