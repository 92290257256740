.confirm-popup-content-component {
    &-title {
        font-size: 25px;
        line-height: 35px;
        font-weight: 400;
    }

    &-icon {
        margin-right: 15px;
    }

    &-content {
        margin-top: 50px;
        margin-bottom: 70px;
        font-size: 1rem;
        line-height: 22px;
    }
}