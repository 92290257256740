@import 'styles/extends.scss';

.button-component {
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: 'Inter';
    line-height: 20px;
    border: none;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;

    svg {
        margin-right: 10px;
    }

    &.rounded {
        border-radius: 50px;
    }

    &.small {
        padding: 10px 11px;
    }

    &.large {
        padding: 15px 100px;
    }

    &.xsmall {
        padding: 5px 11px;
        font-weight: 700;
    }

    &.blue {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        box-shadow: var(--shadow-1);
        font-weight: 700;
    }

    &.transparent {
        border: 1px solid var(--color-blue);
        color: var(--color-light-blue);
        font-weight: 700;
        background-color: var(--color-white);
    }

    &.black {
        background-color: var(--color-light-black);
        color: var(--color-white);
    }

    &.plain {
        background-color: transparent;
        color: var(--color-light-black);
        font-weight: 700;
        border: none;
        box-shadow: none;
    }

    &.red {
        background-color: var(--color-red);
        color: var(--color-white);
        font-weight: 700;
        border: none;
        box-shadow: none;
    }

    .icon {
        margin-right: 10px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    &.ghost {
        @extend %ghost-animation;

        span {
            visibility: hidden;
        }
    }
}