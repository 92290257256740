.tabs-component {
    padding-top: 18px;
    border-bottom: 2px solid #E6E6E6;

    .MuiTabs-indicator {
        background-color: var(--color-light-black);
    }

    .Mui-selected {
        font-weight: 700 !important;
        color: var(--color-light-black) !important;
    }

    .tab {
        padding: 7px 17px;
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 21px;
        border-radius: 20px;
        font-size: 0.875rem;
        color: var(--color-light-black);
        font-family: var(--inter);
        text-transform: capitalize;

        &.active {
            font-weight: 700;
            background-color: var(--color-25);
        }

        &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
            color: var(--color-light-black);
            font-weight: 700;
        }

        &--disabled {
            pointer-events: none;
        }
    }

}

.tabs-tab-panel {
    height: 100%;
    overflow: auto;
    flex: 1 1;

    &-content {
        height: 100%;
    }

    &-parent {
        height: 100%;
        overflow: auto;
        padding: 20px 0px;
    }
}

.old-tabs-component-main-wrapper {
    display: flex;

    .old-tabs-component {

        padding-top: 33px;
        border-bottom: 0.1rem solid #E6E6E6;
        display: flex;
        flex: 1 1;
        gap: 25px;
        max-width: -webkit-fill-available;
        overflow: scroll;

        &>.tab {
            padding-bottom: 14px;
            border-radius: 0;
            background-color: transparent !important;
            color: var(--color-light-black);
            font-weight: 300;

            &.active {
                border-bottom: 3px solid #1E294E;
                font-weight: 700;
                color: #1E294E;
            }
        }
    }

    .resources-content {
        display: flex;
        gap: 10px;
        align-items: flex-end;
    }

    .title-panel {
        display: flex;
        justify-content: flex-end;
        box-shadow: 0px 0px 4px 0px transparent;
        width: fit-content;
        float: right;
        padding: 16px;
        border-radius: 12px;
        gap: 10px;
        align-items: center;
        box-shadow: 0px 0px 4px 0px #00000040;
        height: 64px;

        &-content {
            display: flex;
            flex-direction: column;
            gap: 5px;

            ::first-letter {
                text-transform: capitalize;
            }

            &-heading {
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
            }
        }

        .account-title {
            color: var(--color-light-blue);
            margin-left: 2px;
        }
    }
}