.gke-node-pools-component {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;



    .hollow-green-button {
        color: green;
        background: white;
        border: none;
        height: 40px;
        border-radius: 4px;
    }

    .resource-table-cell-actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .resource-table-cell-name {
        .saving-perc {
            color: #1EB564;
            font-family: Inter;
            font-size: 13.423px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.176px;
            letter-spacing: -0.268px;
        }
    }

    .old-tabs-component-main-wrapper .old-tabs-component {
        padding-top: 0px !important;
    }

    &_tabs {
        padding-top: 0px;
    }

    .node-pools-list {
        .blue-title {
            color: var(--color-dark-blue)
        }

        .hollow-red-button {
            color: #BA180F;
            background: white;
            border: 1px solid #BA180F;
            height: 40px;
            border-radius: 4px;
        }
    }

    &-content {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;


        .grey-span {
            color: var(--color-28);
        }

        .blue-span {
            color: var(--color-dark-blue);
            margin-left: 5px;
        }

        &__header {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            .button-component {
                width: 260px;
            }

            .text-input-component {
                width: 35%;
                margin-left: auto;
            }

            &__button-group {
                display: inline-flex;

                .drain_node {
                    font-family: var(--inter);
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    width: fit-content;
                }

                .select_all {
                    margin-left: 5px;

                    .MuiFormControlLabel-label {
                        font-family: var(--inter);
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    svg {
                        color: var(--color-dark-blue)
                    }
                }
            }

            &__customise-columns {
                display: flex;
                justify-content: flex-end;
            }
        }

        &__wrapper {
            overflow: hidden;
            display: flex;
            max-height: 100%;
            height: 100%;

            .gke-node-pools-data {
                height: 100%;
                max-height: 100%;
                flex: 1;
                max-width: 100%;

                &.collapsed {
                    max-width: calc(100% - 290px);
                }
            }
        }
    }

    .gke-node-pools-nodes {
        height: calc(100%);
    }
}