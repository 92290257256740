.policies-tile-view {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    width: calc(100% - 15px);
    overflow: auto;


    &-wrapper {
        flex-grow: 1;
        cursor: pointer;
        position: relative;
        height: 155px;
        max-width: 170px;
        min-width: 170px;
        width: 170px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        transition: flex-grow 0.5s ease-in-out,
            max-width 0.5s ease-in-out,
            min-width 0.5s ease-in-out,
            width 0.5s ease-in-out,
            height 0.5s ease-in-out,
            flex-basis 0.5s ease-in-out;

        &-hovered {
            flex-grow: 2;
            max-width: 400px;
            min-width: 400px;
            width: 400px;
            height: 670px;

            .policies-tile-view-wrapper-item:hover {
                background: #FFFFFF;

            }
        }

        &-item {
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 12px;
            border: 1px #D9D9D9 solid;
            text-align: center;
            padding: 10px 10px;
            box-shadow: none !important;

            &__updated {
                background: #FFFFFF;
                border-color: #F4B400;

                &:hover {
                    background: rgba(244, 180, 0, 0.2);
                }
            }

            &__created {
                border-color: #0F9D58;
                background: #FFFFFF;

                &:hover {
                    background: rgba(15, 157, 88, 0.2);
                }
            }
        }

        .by-name-container {
            overflow-wrap: break-word;
        }
    }

    &-content {
        font-size: 0.75rem;
        display: contents;


        &-hovered {
            display: inline-flex;
            gap: 10px;
            justify-content: space-between;
        }
    }



    &-paper {
        &__stepper-container {
            transition: height 0.5s ease-in-out;
            overflow: hidden;

        }

        &__flex-items-header {
            display: flex;
            align-items: center;
            gap: 10px;


            .text-bold {
                font-weight: 500;
                text-transform: capitalize;
                font-size: 1rem;
            }
        }
    }

    .show {
        height: 355px;
    }

    .close-stepper {
        background: #ECF2FF;
        height: 30px;
        width: 33px;
        border-radius: 50px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        cursor: pointer;
    }

    .policy-engine-details__content {

        display: flex;
        flex-direction: column;
        gap: 15px;

        .footer {
            display: flex;
            justify-content: flex-end;
        }

        .header {
            box-sizing: border-box;
            border-radius: 12px;
            border: 1px #D9D9D9 solid;
            text-align: center;
            padding: 15px;
            box-shadow: none !important;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 20px;
        }

        &__flex-items {
            display: flex;
            gap: 30px;
            align-items: center;
            justify-content: space-between;

            .MuiSlider-root {
                margin: 0px 10px;
            }

            &-header {
                display: flex;
                gap: 10px;
                align-items: center;
                border-bottom: 1px solid #D9D9D9;
                padding: 0px 0px 10px;

                .text-bold {
                    font-weight: 500;
                    text-transform: capitalize;
                    font-size: 1rem;
                }

                .cross-icon {
                    background: #ECF2FF;
                    height: 25px;
                    width: 25px;
                    border-radius: 50px;
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: larger;
                }
            }

            &__title {
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 10px;
                text-transform: capitalize;
                padding: 0px 0px 10px 0px;
            }

            .policies-engine-dropdown {
                background: #FAFAFA;
                border: 1px solid #D9D9D9;
                border-radius: 12px;
                min-width: 345px;
                padding: 20px;
            }

        }

        .green {
            color: #019E10;
        }
    }
}


.policies-engine-dropdown-popover {

    .MuiPopover-paper {
        min-width: 330px !important;
        max-width: 330px !important;
    }
}