html {
    line-height: 1.15;
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
    transition: font-size 0.3s ease, border 0.3s ease;
}

@media (max-width: 1200px) {
    html {
        font-size: 0.875rem;
    }
}

@media (max-width: 768px) {
    html {
        font-size: 0.75rem;
    }
}

@media (min-resolution: 2dppx) {
    html {
        border-width: 0.125rem;
    }
}

body {
    margin: 0;
    line-height: 1;
    font-size: 1rem;
}

h1 {
    font-size: 2rem;
    /* 32px */
}

h2 {
    font-size: 1.5rem;
    /* 24px */
}

p {
    font-size: 1rem;
    /* 16px */
}

main {
    display: block;
}

hr {
    height: 0;
    overflow: visible;
}

pre {
    font-size: 1em;
}

a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
}

abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    text-decoration: underline dotted;
    /* 2 */
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
    appearance: button;
    background-color: transparent;
    border: none;
    color: inherit;
    padding: 0;
    cursor: pointer;
    line-height: inherit;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border: none;
    padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

input {

    &[type='checkbox'],
    &[type='radio'] {
        box-sizing: border-box;
        padding: 0;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        height: auto;
    }

    &[type='search'] {
        -webkit-appearance: textfield;
        appearance: textfield;
        outline-offset: -2px;
    }

    &[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--color-white) inset !important;
        /* Change to your desired background color */
    }
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
    cursor: pointer;
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: var(--inter);
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

* {
    box-sizing: border-box;
}