.client-overview-component {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__info {
        h3 {
            color: var(--Base-Black, #000);
            font-family: Inter;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }

        &__details {
            display: flex;
            align-items: center;
            gap: 20px;

            .flex-box-container {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;



        .report-dropdown {
            border-radius: 4px;
        }
    }

    &__client {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        &-details {
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: none;
            padding: 32px 26px;
            flex-direction: column;
            flex: 15%;
            gap: 20px;

            &__persona {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                .title {
                    color: var(--Base-Black, #000);
                    font-family: Inter;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                }
            }

            &__contact {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0;
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
                display: flex;
                padding: 20px;
                align-items: center;
                gap: 20px;
                width: 100%;
                justify-content: space-between;

                &-details {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex-direction: row-reverse;
                }
            }

            &__info {
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
                width: 100%;

                h3 {
                    color: var(--Base-Black, #000);
                    font-family: Helvetica;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 550;
                    line-height: 24px;
                }

                span {
                    color: var(--color-black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                }
            }
        }

        &__charts {
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 32px 26px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 20%;

            &-container {
                height: 200px;
                width: 400px;
            }

            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }
        }

        &__savings {
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 32px 26px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 20%;


            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }

            &-amount {
                color: var(--Base-Black, #000);
                text-align: center;
                font-family: Inter;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
            }

            &__trends {
                display: flex;
                align-items: center;
                gap: 10px;

                .time-span {
                    color: #4A5568;
                    font-family: Helvetica;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }
        }

        &__plan {
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 32px 26px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 20%;
            max-width: 430px;

            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }

            &-amount {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0;
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
                display: flex;
                padding: 32px 26px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                width: 100%;

                span {
                    color: var(--Base-Black, #000);
                    text-align: center;
                    font-family: Inter;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                }

                .time-span {
                    color: #4A5568;
                    font-family: Helvetica;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            &-active {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0;
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
                display: flex;
                padding: 32px 26px;
                align-items: flex-start;
                gap: 20px;
                width: 100%;
                justify-content: space-between;


                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                span {
                    color: var(--Base-Black, #000);
                    text-align: center;
                    font-family: Inter;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                }

                .time-span {
                    color: #4A5568;
                    font-family: Helvetica;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            &__trends {
                display: flex;
                align-items: center;
                gap: 10px;

                .time-span {
                    color: #4A5568;
                    font-family: Helvetica;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }
        }
    }

    &__tables {
        display: flex;
        // flex-direction: column;
        gap: 10px;
        // border-radius: 12px;
        // border: 0.06rem solid #CBD5E0;
        // background: var(--Base-White, #FFF);
        // box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
        // padding: 20px;

        .heading {
            color: var(--Base-Black, #000);
            font-family: Inter;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }

        &-container {
            display: flex;
            gap: 10px;
            flex-direction: column;
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            padding: 26px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 40%;
        }

        .client-list-component {}

        &-stats {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &-details {
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0;
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
                display: flex;
                padding: 32px 26px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;

                &.resources {
                    background: rgb(255, 198, 88, 0.3);
                    border: 1px solid #ffc658;

                }

                &.discoveries {
                    background: rgb(136, 132, 216, 0.3);
                    border: 1px solid #8884d8;
                }

                &.projects {
                    background: rgb(130, 202, 157, 0.3);
                    border: 1px solid #82ca9d;
                }

                &.scheduled {
                    background: rgb(66, 133, 244, 0.3);
                    border: 1px solid #4285F4;
                }
            }

            &-chart {
                height: 498px;
                width: 450px;
                border-radius: 12px;
                border: 0.06rem solid #CBD5E0;
                background: var(--Base-White, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
                padding: 32px 26px;
                flex: 30%;
            }
        }
    }
}