.snippet-board-component {
    min-height: 500px;
    max-width: 700px;
    min-width: 700px;
    width: 700px;

    .MuiPaper-root {
        padding: 30px;
        padding-top: 10px;
        max-width: 700px;
        width: 700px;
    }

    .drawer-header {
        padding: 0;
        display: flex;
        justify-content: flex-end;
    }

    .tabs-component {
        padding-top: 0;
    }

    .popup-component-content {
        padding-top: 0%;
    }

    .popup-component-header {
        padding-top: 20px;
        padding-right: 20px;
    }

    &__yaml {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;

        ul,
        li {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .step-title {
                font-size: 15px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #2e3646;
            }
        }
    }

    .yaml__snippet {
        min-height: 40px !important;
        max-height: 100px !important;
    }

    .tabs-label {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .command-title {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #4a5568;
    }

    .placeholder {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #4a5568;
        font-style: italic;
    }
}