.MuiDateCalendar-root {
    font-family: var(--inter);
    width: 240px!important;

    .MuiPickersCalendarHeader-root {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
    }

    .MuiPickersDay-root {
        height: 36px;
        width: 32px;
        font-weight: 700;
        border-radius: 10px;
    }

    .MuiPickersDay-today {
        border: 0!important;
        color: var(--color-dark-blue);
    }

    .MuiDayCalendar-weekDayLabel {
        width: 32px;
        height: 24px;
    }

    .Mui-selected {
        background-color: var(--color-27)!important;
        color: var(--color-dark-blue)!important;
    }

    .MuiPickersArrowSwitcher-button {
        padding: 0;
    }
}

.MuiPopover-paper {
    border-radius: 12px!important;
    box-shadow: var(--shadow-3)!important;
}

.MuiTooltip-tooltip {
    padding: 14px 8px !important;
    background-color: var(--color-9)!important;
    color: var(--color-black)!important;
    font-family: var(--inter) !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
}

.MuiDrawer-paper {
    width: 80%;
}
