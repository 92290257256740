.component-content {
    &-header {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        &__filter-section {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &__report-dropdown {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &__apply-tag-component {
            display: flex;
            align-items: center;
            gap: 5px;

            .apply-tags {
                display: flex;
                padding: 9px 16px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 71px;
                background: var(--color-dark-blue);
                max-width: max-content;
                flex-direction: row-reverse;
                color: #FFF;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 135%;

                svg {
                    height: 14px;
                    width: 14px;
                    margin: 0;
                    color: #FFF;
                }
            }
        }

        .add-project {
            background-color: var(--color-green) !important;
        }

        .filter-button {
            display: flex;
            padding: 9px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 71px;
            background: var(--color-dark-blue);
            max-width: max-content;
            flex-direction: row-reverse;
            color: #FFF;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;

            svg {
                height: 13px;
                width: 13px;
                margin: 0;
            }
        }

        .text-input-component {
            flex: 1;
            max-width: 300px;
            min-width: 300px;
        }

        .report-dropdown {
            display: flex;
            padding: 9px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 71px;
            background: var(--color-dark-blue);
            color: #FFF;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;

            .placeholder {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    font-size: medium;

                    path {
                        stroke: inherit;
                        outline: inherit;
                    }
                }
            }

            svg {
                path {
                    stroke: #FFF;
                    outline: #FFF;
                }
            }
        }
    }

}