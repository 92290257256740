   .copy-button {
       display: flex;
       align-items: center;
       flex-direction: row-reverse;

       .copy-feedback {
           opacity: 0;
           transition: opacity 0.5s ease-in-out;
           font-size: 0.875rem;
           color: green;
           margin-left: 10px;
       }

       .copy-feedback.fade-in {
           opacity: 1;
       }

       .copy-feedback.fade-out {
           opacity: 0;
       }

       .green {
           color: #019E10
       }

       .blue {
           color: var(--color-dark-blue);
       }
   }