.dropdown-component {
    position: relative;
    width: 100%;

    &.input {
        .dropdown-component-title {
            background-color: var(--color-white);
            border: 1px solid var(--color-2);
            color: var(--color-1);
        }
    }

    &.dropdown {
        .dropdown-component-title {
            color: inherit;
        }
    }

    &-title {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        border-radius: 12px;

        &-value {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &-tools {
        margin-left: 10px;
    }

    &__disabled {
        pointer-events: none;
        cursor: default !important;
    }
}