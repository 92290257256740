@import 'styles/extends.scss';

.action-details-component {
    width: 100%;
    height: 100%;
    padding: 40px;
    border-radius: 10px;

    .hollow-green-button {
        color: green;
        background: white;
        border: none;
        height: 40px;
        border-radius: 4px;
    }

    h2 {
        font-size: 25px;
        color: var(--color-light-black);
        font-weight: 400;
        margin-bottom: 55px;

        svg {
            margin-right: 15px;
            color: var(--color-light-blue);
        }
    }

    &-controls {
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        margin-bottom: 30px;

        &-item {
            &.name {
                display: flex;
                font-size: 1rem;
                font-weight: 700;
                text-transform: capitalize;
                align-items: center;

                &-detail {
                    color: var(--color-light-blue);
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                }
            }

            &.icon {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                margin-left: 25px;

                svg {
                    max-width: 28px;
                }
            }

            &.buttons {
                display: flex;
                align-items: center;
                gap: 20px;
            }

            &-actions {
                display: flex;
                align-items: center;
                gap: 10px;

            }

            &-cost {
                display: flex;
                font-weight: 700;
                font-size: 1.125rem;
                flex-direction: column;
                line-height: 21px;
                white-space: nowrap;

                .saving {
                    background-color: var(--color-dark-green);
                    color: var(--color-white);
                    width: fit-content;
                    padding: 0 10px;
                    margin-top: 4px;
                    border-radius: 4px;
                    font-size: 0.75rem;
                    font-weight: 500;
                    width: 100%;
                    text-align: center
                }
            }
        }
    }

    &-controls,
    &-info-item {
        border-radius: 12px;
        box-shadow: var(--shadow-5);
    }

    &-info {

        &-top,
        &-bottom {
            display: flex;
        }

        &-top {
            margin-bottom: 30px;
        }

        .action-details-component-info-item {

            &.right {
                display: flex;
            }

            .action-details-component-info-item-block {
                flex: 1;

                &:not(:last-child) {
                    margin-right: 25px;
                }

                &-title {
                    @extend %text-ellipsis;
                    width: 100%;
                    border-radius: 10px;
                    line-height: 19px;
                    font-weight: 500;
                    font-size: 0.875rem;
                    padding: 0 10px;
                    color: var(--color-light-black);
                    margin-bottom: 25px;
                }

                &.after {
                    display: flex;
                    flex-direction: column;
                    flex: 2;

                    .action-details-component-info-item-li {
                        color: var(--color-green);
                        font-weight: 700;
                    }

                    .action-details-component-info-item-block-title {
                        background-color: var(--color-32);
                    }
                }

                &.current {
                    .action-details-component-info-item-li {
                        color: var(--color-33);
                        font-weight: 700;
                    }

                    .action-details-component-info-item-block-title {
                        background-color: var(--color-31);
                    }
                }
            }
        }

        &-item {
            padding: 15px;

            &.left {
                margin-right: 30px;
                min-width: 375px;
                max-width: 375px;
            }

            &.right {
                flex: 1;
            }

            &-header {
                display: flex;
            }

            h3 {
                margin-bottom: 25px;

                &.resource-title {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                    }
                }

                &.resource-title_DB {
                    position: relative;

                    div {
                        &.resource-title_head {
                            display: flex;

                            svg {
                                margin-right: 10px;
                            }
                        }
                    }

                    span {
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: var(--color-light-blue);
                        position: absolute;
                        top: 23px;
                        left: 42px;

                    }
                }
            }

            &-li {
                @extend %text-ellipsis;
                font-size: 0.875rem;
                display: flex;
                line-height: 19px;

                .diff {

                    display: flex;
                    gap: 5px;
                    align-items: center;
                    flex-direction: row-reverse;

                    .icon {
                        height: 19px;
                        width: 10px;
                    }
                }

                &.resource-name {
                    .tile-component {
                        margin-left: 10px;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                .label,
                .value {
                    display: inline-block;
                }

                .label {
                    color: var(--color-dark-grey);
                    width: 45%;
                }

                .value {
                    font-weight: 700;
                    color: var(--color-light-black);
                    white-space: wrap;
                    width: calc(55% - 10px);
                    margin-left: 10px;

                    &.account-name {
                        color: var(--color-light-blue);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.action-details-confirm-tile {
    margin-bottom: 50px;
    margin-top: 15px;
    text-transform: capitalize;
}

.confirm-resource-action-popup {
    .popup-component-buttons {
        .button-component {
            width: 140px;
        }
    }
}