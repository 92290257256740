.calendar-component {
    border-radius: 12px;
    background-color: var(--color-white);
    padding: 16px;

    .buttons {
        display: flex;
        justify-content: flex-end;

        .button {
            font-weight: 500;
            padding: 6px 8px;

            &.selected {
                background-color: var(--color-27);
                border-radius: 12px;
                color: var(--color-light-blue);
            }
        }
    }

    .time {
        &-value {
            display: flex;
            padding: 8px 0 16px 0;

            input {
                margin-right: 8px;
            }
        }

        &-inputs {
            display: flex;

            .dropdown-component {
                margin-right: 10px;
                width: 70px;

                &-title {
                    padding: 7px 8px;
                }
            }
        }
    }
}