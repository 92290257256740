.connect-cloud-ui-component {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 30px;
    align-items: center;
    justify-content: center;
    background: var(--White, #FFF);
    height: -webkit-fill-available;

    &-top {
        background: #FFF;
        display: flex;
        flex-direction: column;
        gap: 43px;
        align-items: center;
    }

    &-permissions {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 0.06rem solid #CBD5E0;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
        padding: 18px 19px;
        gap: 18px;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;

        &_typography {
            color: var(--Gray-500, #2E3646);
            text-align: center;
            font-family: Inter, var(--inter);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.016px;
        }

        &_view {
            display: inline-flex;
            align-items: center;
            color: #4285F4;
            text-align: center;
            font-family: Inter, var(--inter);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            margin-left: 30px;
            cursor: pointer;
        }
    }

    &__heading-content {
        color: var(--Gray-500, #2E3646);
        text-align: center;
        font-family: Inter, var(--inter);
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &-waiting {
            color: #4285F4;
            margin-left: 3px;
        }
    }

    &__sub-content {
        color: var(--Gray-500, #2E3646);
        text-align: center;
        font-family: Inter, var(--inter);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.018px;
        white-space: pre-wrap;
        word-wrap: break-word;
        max-width: 700px;
    }

    &__sub-content_transition {
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        position: relative;

        .text-item {
            display: inline-block;
            animation: slideRight 9s both infinite linear;
            color: var(--Gray-500, #2E3646);
            text-align: center;
            font-family: Inter, var(--inter);
            font-size: 1.125rem;
            font-style: normal;
            letter-spacing: -0.018px;
        }
    }

    .aws-connect-box {
        height: fit-content;

        .column-flex-box {
            width: 100%;
        }

        span {
            vertical-align: middle;
            width: 100%;
            margin-right: 5px;
        }

        &__aws-input {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            input {
                height: 45px;
            }
        }

        &__aws-connect-button {
            width: 100%;
            background: #28f;

            span {
                color: #FFF;
                display: contents;
            }
        }
    }

    &__content-section {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    .connect-cloud-button {
        display: inline-flex;
        flex-direction: row-reverse;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        background: #4285F4;
        color: #FFF;
        text-align: center;
        font-family: Inter, var(--inter);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 43px;
    }

    &_cloud-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .isSelected {
            border-radius: 14px;
            border: 3px solid #4285F4;
        }

        &_connect {
            border-radius: 12px;
            border: 0.06rem solid #CBD5E0;
            background: #FFF;
            display: inline-flex;
            padding: 20px 22px;
            align-items: center;
            gap: 28px;
            max-width: 441px;
            width: 441px;
            height: 145px;
            cursor: pointer;
            position: relative;

            .column-flex-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                width: 291px;

                p {
                    position: absolute;
                    right: 10px;
                    padding: 12px;
                    background: #EAF5FF;
                    border: 1px solid #4285F4;
                    color: #4285F4;
                    height: 21px;
                    border-radius: 4px;
                    font-size: 13px;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    top: 6px;
                }
            }

            h3 {
                color: var(--Base-Black, #000);
                font-family: Inter, var(--inter);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;

                span {
                    color: var(--Base-Black, #000);
                    font-family: Inter, var(--inter);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;


                }
            }

            span {
                color: var(--Base-Black, #000);
                font-family: Inter, var(--inter);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                width: 291px;
                text-align: justify;

                a {
                    color: var(--Base-Black, #28F);
                    font-family: Inter, var(--inter);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;

                    &:hover {
                        text-decoration: underline;
                    }
                }

            }
        }
    }
}