   .aws-region {
       &:focus-visible {
           outline: none;
       }

       &-select {
           &__control {

               &:hover,
               &:focus,
               &:focus-visible,
               &--menu-is-open,
               &--is-focused {
                   border: 1px solid var(--color-16);
                   border-radius: 7px;
                   box-shadow: none;
               }

               height: 56px;
           }
       }
   }

   .custom-region-menu {
       display: flex;
       align-items: center;
       width: 100%;
       justify-content: space-between;
       padding: 10px;

       .region-label {
           flex: 60%;
       }

       .region-code {
           flex: 40%;
           max-width: fit-content;
       }
   }