.account-resources-list {
    .resource-table-cell {

        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;

            .hollow-green-button {
                color: green;
                background: white;
                border: none;
                height: 40px;
                border-radius: 4px;
            }
        }

        &-name,
        &-account {
            color: var(--color-dark-blue);
            text-transform: capitalize;
            font-weight: 400;
        }

        &-name {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                cursor: pointer;
            }

            .tile-component {
                display: block;
                width: max-content;

            }
        }

        &-cost-impact {
            display: flex;
            align-items: center;

            svg {
                height: 16px;
                width: 12px;
                margin-right: 5px;
            }
        }

        &-flex-items {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}

.snapshots-component {
    flex-direction: column;
    display: flex;
    height: 100%;

    &__content {
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__tiles {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    .apply-projections-component-advance {
        margin-top: 25px;
    }

    .tabs-tab-panel-content {
        height: 100%;
        overflow: hidden;
    }

    h1 {
        font-size: 30px;
    }

    .tabs-component {
        padding-top: 0;
    }

    .component-content-header {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .collapsible-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

.container-images-component {
    flex-direction: column;
    gap: 25px;
    display: flex;

    &__content {
        height: calc(100% - 35px);
    }


    &__tiles {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    h1 {
        font-size: 30px;
    }

    .component-content-header {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .collapsible-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}