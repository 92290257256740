.notification-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 10px;
    z-index: 2;


    .notification-component {
        padding: 20px 30px;
        height: 48px;
        font-size: 0.875rem;
        color: var(--color-black);
        animation: 0.2s slide-top;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        justify-content: center;

        &-icon {
            margin-right: 10px;
        }

        &.success {
            background-color: var(--color-34);
        }

        &.error {
            background: #FFE7E7;
        }

        &.info {
            background-color: var(--color-36);
        }
    }
}