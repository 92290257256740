@import 'styles/extends.scss';

.text-input-component {
    position: relative;

    &.with-label {
        padding-top: 4px;
    }

    label {
        position: absolute;
        font-size: 0.875rem;
        padding: 0 3px;
        background-color: var(--color-white);
        top: -3px;
        left: 8px;
        color: var(--color-17);
    }

    &-input {
        border-radius: 7px;
        padding: 0 16px;
        color: var(--color-light-black);
        border: 1px solid var(--color-15);
        width: 100%;
        height: 56px;

        &.search {
            padding: 9px 9px 9px 46px;
            background: url('../../assets/svgs/search.svg') no-repeat 20px center;
            margin: 0 !important;
            border-radius: 30px;
            height: 45px;
            background-color: var(--color-27);
            border: none;
            font-size: 0.875rem;
            color: var(--color-28);
        }

        &::placeholder {
            color: var(--color-1);
        }

        &:focus {
            outline: none;
            border: 1px solid var(--color-16);
        }

        &.ghost {
            @extend %ghost-animation;
        }

        &.inline {
            outline: none;
            border: none;
            background: none;
            border-radius: 0;
            padding: 0;
            color: inherit;

            &.invalid {
                border-bottom: 1px solid var(--color-red);
            }
        }
    }

    .validation-message-component {
        position: absolute;
        bottom: -20px;
        left: 0;
        font-size: 13px;
    }
}