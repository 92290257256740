[data-cyid="cy-subscription-plan-connection"] {
    z-index: 1500;

    .MuiBackdrop-root {
        backdrop-filter: blur(1.85px);
    }
}


.subscription-plan-popup {
    min-width: 500px;

    &-component {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;


            h1 {
                color: #2E3646;
                font-family: Inter, var(--inter);
                font-size: 35px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
            }

            p {
                color: var(--Dark-Grey, #454746);
                font-family: Inter, var(--inter);
                font-size: 12.924px;
                font-style: normal;
                font-weight: 400;
                line-height: 18.463px;
                letter-spacing: -0.258px;
            }

            &-buttons {
                display: flex;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            &-footer {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                gap: 15px;

                &-upgrade {
                    border-radius: 6px;
                    background: #356bc6;
                    padding: 15px 22px;
                    color: var(--Base-White, #FFF);
                    text-align: center;
                    font-family: Inter, var(--inter);
                    height: 43px;
                }

                &-cancel {
                    background: var(--Base-White, #fff);
                    border: 0.06rem solid #CBD5E0;
                    border-radius: 6px;
                    box-shadow: 0 1px 2px 0 #1118270d;
                    color: #000;
                    color: var(--Base-Black, #000);
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 700;
                    height: 43px;
                    line-height: 14px;
                    text-align: center;
                }
            }

            p {
                color: var(--Dark-Grey, #454746);
                font-family: Inter, var(--inter);
                font-size: 12.924px;
                font-style: normal;
                font-weight: 400;
                line-height: 18.463px;
                letter-spacing: -0.258px;
            }

            &-tiles {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                &-tile {
                    min-width: 200px;
                    height: 140px;
                    border-radius: 12px;
                    border: 0.06rem solid #CBD5E0;
                    background: var(--Base-White, #FFF);
                    box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
                    padding: 18px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    cursor: pointer;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    h3 {
                        color: var(--Gray-500, #2E3646);
                        font-family: Inter, var(--inter);
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    &-content {
                        &-plan-selector {
                            border-radius: 15px;
                            border-radius: 6px;
                            background: var(--Primary-Blue, #4285F4);
                            padding: 12px;
                            color: var(--Base-White, #FFF);
                            text-align: center;
                            font-family: Inter, var(--inter);
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                            color: #FFF;
                        }

                        &-price {
                            display: inline-flex;
                            align-items: center;

                            span {
                                color: #121212a6;
                                font-family: Inter, var(--inter);
                                font-size: 35px;
                                font-style: normal;
                                font-weight: 800;
                                line-height: normal;
                            }

                            p {
                                color: #999898;
                                font-family: Inter, var(--inter);
                                font-size: 13.278px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: 0.266px;
                            }
                        }

                        &-footer {
                            text-align: center;
                        }
                    }
                }

                .selected_plan {
                    border-radius: 12px;
                    border: 1px solid #A0EBC7;
                    background: #E3FFEF;
                    box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
                    padding: 18px 20px;
                    cursor: not-allowed;


                    button {
                        background: #4ba87b;
                    }
                }

                .current_plan {
                    border-radius: 12px;
                    border: 1px solid #A0BEFF;
                    background: var(--Light-blue, #ECF2FF);
                    box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
                    padding: 18px 20px;
                }
            }
        }
    }
}