.discoveries-placeholder-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    h1 {
        max-width: 545px;
        color: var(--color-light-blue);
        font-weight: 400;
        font-size: 1.125rem;
        text-align: center;
        line-height: 21px;

        span {
            color: var(--color-light-black);
            font-weight: 700;
        }
    }

    button {
        padding: 16px 30px;
        background-color: var(--color-19);
        border: 1px dashed var(--color-light-blue);
        color: var(--color-light-black);
        font-size: 1rem;
        line-height: 21px;
        font-weight: 500;
        border-radius: 12px;
        display: flex;
        align-items: center;

        svg {
            &:first-of-type {
                margin-right: 10px;
            }

            &:last-of-type {
                margin-left: 10px;
            }
        }
    }
}



@keyframes slideRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}