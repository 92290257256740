.billing-overview-component {
    height: 100%;

    .page-header-component-top {
        margin-bottom: 25px;
    }

    &__main {
        height: calc(100% - 200px);
        display: flex;
        flex-direction: column;
        overflow: auto;

        &__chart {
            border-radius: 12px;
            box-shadow: var(--shadow-5);
            padding: 20px;
            margin: 15px 1px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1 1;
            max-width: -webkit-fill-available;
            height: 450px;

            &--header {
                display: flex;
                align-items: center;
                gap: 10px;

                h3 {
                    color: var(--Secondary-Black, #2A2A2A);
                    font-family: "Inter";
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                }
            }

            &--area {
                .main-tooltip {
                    border-radius: 13.278px;
                    background: #2E3646;
                    box-shadow: 0px 193.645px 54.221px 0px rgba(0, 0, 0, 0.00), 0px 123.933px 49.794px 0px rgba(0, 0, 0, 0.01), 0px 69.712px 42.049px 0px rgba(0, 0, 0, 0.05), 0px 30.983px 30.983px 0px rgba(0, 0, 0, 0.09), 0px 7.746px 16.598px 0px rgba(0, 0, 0, 0.10);
                    display: inline-flex;
                    padding: 15.492px 17.705px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 21.024px;
                    z-index: 99999;

                    h4,
                    h5 {
                        color: var(--Base-White, #FFF);
                        font-family: Inter, var(--inter);
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.181px;
                        text-transform: uppercase;
                        z-index: 3;
                    }

                    span {
                        color: var(--Base-White, #FFF);
                        font-family: Inter, var(--inter);
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 13.574px;
                        letter-spacing: -0.01px;
                        z-index: 3;
                    }

                }


                .tooltip-date {
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                .tooltip-content {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                }

                .tooltip-item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                .recharts-legend-item-text {
                    color: var(--Secondary-Black, #2A2A2A) !important;
                    font-family: "Inter";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }
}