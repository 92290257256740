.progress-bar-component {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    .progress-bar {
        width: 100%;
        border-radius: 8px;
        background-color: var(--color-30);
        height: 10px;

        &-value {
            height: inherit;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    &-title {
        color: var(--color-dark-grey);
        font-size: 0.875rem;
    }
}