@import 'styles/reset.scss';
@import 'styles/mixins.scss';
@import 'styles/base.scss';
@import 'styles/colors.scss';
@import 'styles/animations.scss';
@import 'styles/react-material.scss';
@import '/src/assets/fonts/stylesheet.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


:root {
    @extend %colors;
    --inter: 'Inter', sans-serif;
}

html,
body {
    height: 100%;
}

body {
    font-family: var(--inter);

    &>div#root {
        height: 100%;
    }

    .highlight {
        background-color: yellow;
        display: inline;
    }

    .text-center {
        text-align: center !important;
    }
}