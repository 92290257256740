.popup-component {
    background-color: var(--color-white);

    &-header {
        height: 35px;
        background-color: var(--color-20);
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
    }

    &-content {
        padding: 40px;
    }

    &-footer {
        display: flex;
        justify-content: space-between;
    }


    &-buttons {
        display: flex;
        justify-content: flex-start;
        // margin: 0 0 0 auto;

        .button-component {
            padding: 10px 46px;
            font-size: 0.875rem;


            &:first-of-type {
                margin-right: 30px;
            }
        }

        &.single {
            justify-content: center;
        }
    }

    .css-ahj2mt-MuiTypography-root {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        margin-left: 6px;

    }

    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        color: var(--color-dark-blue);
    }
}