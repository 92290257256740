.loading-progress-component {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    z-index: 1400;

    &-progress {
        width: 50%;
        margin-bottom: 20px;
        background-color: white;
        color: var(--color-dark-grey);
    }

    p {
        color: var(--color-white);
        font-size: 0.875rem;
        margin-bottom: 10px;
        font-weight: 300;
    }
}
