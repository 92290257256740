%colors {
    --color-white: #fff;
    --color-black: #000;
    --color-light-black: #2A2A2A;
    --color-red: #BA180F;
    --color-light-red: #FFC9B8;
    --color-dark-blue: #185EE6;
    --color-blue: #0071F7;
    --color-light-blue: #4285F4;
    --color-1: #5A5A5A;
    --color-2: #CECECE;
    --color-3: #EDEDED;
    --color-4: #FAFAFA;
    --color-5: rgba(255, 255, 255, 0.5);
    --color-6: rgba(0, 0, 0, 0.7);
    --color-8: rgba(34, 136, 255, 0.2);
    --color-9: #D9D9D9;
    --color-10: #F3F3F3;
    --color-11: #E5E5E5;
    --color-12: #ECECEC;
    --color-13: #CECECE;
    --color-14: #202020;
    --color-green: #019E10;
    --color-yellow: #EFB200;
    --color-light-green: #DAF4C5;
    --color-dark-green: #549700;
    --color-light-yellow: #F4E7A3;
    --color-bright-yellow: #FDD712;
    --color-15: #81868A;
    --color-16: #1F82F8;
    --color-17: #7D7D7D;
    --color-18: #F6F8FC;
    --color-19: #ECF2FF;
    --color-20: #F1F3F4;
    --color-21: #CFCFCF;
    --color-22: #2288FF;
    --color-23: #F6F6F6;
    --color-24: #E6F1FE;
    --color-25: #D6E2FB;
    --color-26: #C3D4F9;
    --color-dark-grey: #454746;
    --color-27: #E1EDFF;
    --color-28: #5E5E5E;
    --color-29: rgba(125, 125, 125, 0.2);
    --color-30: #E7E7E7;
    --color-31: rgba(203, 151, 0, 0.4);
    --color-32: rgba(1, 158, 16, 0.4);
    --color-33: #CB9700;
    --color-34: #C1E2C5;
    --color-35: #E2C1C1;
    --color-36: #FFEAAC;
    --color-37: #008253;
    --color-38: #535353;
    --Medium-Grey: #767575;
    --Gray-500: #2E3646;
    --shadow-1: 0px 4px 10px rgba(0, 0, 0, 0.1);
    --shadow-2: 10px 0px 20px rgba(0, 0, 0, 0.05);
    --shadow-3: 0px 8px 24px rgba(0, 0, 0, 0.15);
    --shadow-4: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    --shadow-5: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    --shadow-6: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
    --shadow-7: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
    --shadow-8: rgba(0, 0, 0, 0.15) -1px 0px 5px;
    --table-borders: 0.05rem solid rgba(0, 0, 0, 0.12);
}