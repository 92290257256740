.unauthorized-component {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .back-link {
        text-align: center;
        color: var(--color-blue);
    }
}