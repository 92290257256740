@keyframes ghost-loading {
    from {
        background-color: var(--color-1)
    }

    to {
        background-color: var(--color-2)
    }
}

@keyframes light-ghost-loading {
    from {
        background-color: var(--color-12)
    }

    to {
        background-color: var(--color-13)
    }
}

@keyframes slide-left {
    from {
        width: 0
    }

    to {
        width: 230px
    }
}

@keyframes slide-top {
    from {
        transform: translateY(-100%)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes slide-bl {
    0% {
        transform: translateY(0) translateX(0)
    }

    100% {
        transform: translateY(100px) translateX(-100px)
    }
}