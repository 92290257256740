.date-dropdown-component {
    display: flex;
    align-items: center;

    &-title {
        color: var(--color-light-blue);
        font-weight: 700;
    }

    button {
        margin-left: 10px;
    }
}