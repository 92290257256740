.select-cloud {
    display: inline-grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;

    &__stepper {
        min-width: 750px;
    }

    .MuiStep-root {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-provider-step {
        display: grid;
        justify-items: center;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    h1 {
        color: var(--Base-Black, #000);
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 54px;
        /* 155.466% */
    }

    p {
        color: #767575;
        font-family: Inter;
        font-size: 1.15rem;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        /* 150% */
    }

    &-list {
        display: flex;
        gap: 20px;

        .connected {
            border-radius: 6px;
            border: 1px solid #4285F4;
        }

        li {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        &-item {
            border-radius: 6px;
            border: 1px solid rgba(203, 213, 224, 0.7);
            background: var(--Base-White, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            min-width: 100px;
            max-width: 100px;
            cursor: pointer;
            transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
            position: relative;
            height: 100px;

            &:hover {
                transform: scale(1.05);
                background-color: var(--black-10, rgba(28, 28, 28, 0.07));
                box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
            }

            &-cloud {
                display: flex;
                align-items: center;
                gap: 10px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;

                svg {
                    width: 70px;
                    height: 40px;
                }
            }

        }
    }
}