@import 'styles/extends.scss';

.collapsible-content {
    height: fit-content;

    .table-component {
        height: 100% !important;
    }
}

.table-component {
    width: 100%;
    height: 100%;

    .icon-column {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .select_all {

        svg {
            color: var(--color-dark-blue);
            font-size: 1.125rem;
        }
    }

    &-wrapper {
        width: 100%;
        height: calc(100% - 50px);
        overflow: scroll;
    }

    table {
        width: 100%;
        max-width: 100%;
        border-spacing: 0;
        position: relative;

    }

    tfoot {
        position: sticky;
        z-index: 2;
        bottom: 0;
        background: var(--color-white);
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: var(--color-white);

        tr {
            th:first-child {
                border-top-left-radius: 12px;
            }

            th:last-child {
                border-top-right-radius: 12px;
            }
        }

    }

    tbody {
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-bottom-right-radius: 12px;
            }
        }
    }




    td {
        position: relative;
        border-bottom: var(--table-borders);

        span {
            color: var(--Base-Black, #000);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }
    }

    th {
        border-top: var(--table-borders);
        border-left: var(--table-borders);

        &:last-child {
            border-right: var(--table-borders);
            text-align: center;

            div {
                display: flex;
                justify-content: center;
            }
        }

        div {
            color: #99999B;
            font-family: Inter;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
        }
    }

    td {
        &.disabled-row {
            background: rgba(0, 0, 0, 0.08) !important;
        }

        &.blue {
            background-color: var(--color-19);
        }

        &.green {
            border: 0.05rem solid #A0EBC7;
            background: #E3FFEF !important;
        }

        &.red {
            border: 0.05rem solid #ea43352e;
            background: #FFE7E7 !important;
        }

        &.yellow {
            border: 0.06rem solid #FFE8A4;
            background: #FFF4D6 !important;
        }

        &.grey {
            background-color: var(--color-23);
        }

        &.black_font {
            color: var(--color-light-black) !important;
        }

        &:first-child {
            border-left: var(--table-borders);

        }

        &:last-child {
            border-right: var(--table-borders);

        }

        .flex-item {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    th,
    td {
        text-align: left;
        border-bottom: var(--table-borders);
        padding: 10px 15px;
        vertical-align: middle;
        background-color: var(--color-white);

        &.collapsed-td {
            border-radius: 12px;
            border: 0.05rem solid #E2E8F0;
            background: var(--Base-White, #FFF);
            box-shadow: 2px 2px 8px 2px rgba(109, 127, 136, 0.06);
        }



        &:first-child,
        &:last-child {
            position: sticky;
            z-index: 1;
        }

        &:last-child {
            padding-right: 10px;
            right: 0;
        }

        &:not(.collapsed-td):first-child {
            padding-left: 10px;
            left: 0;
            width: auto;
            max-width: 340px;

            div,
            a {
                max-width: fit-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.blue {
            background-color: var(--color-19);
        }

        &.green {
            border: 0.05rem solid #A0EBC7;
            background: #E3FFEF !important;
        }

        &.red {
            border: 0.05rem solid #ea43352e;
            background: #FFE7E7 !important;
        }

        &.yellow {
            border: 0.05rem solid #FFE8A4 !important;
            background: #FFF4D6 !important;
        }

        &.grey {
            background-color: var(--color-23);
        }

        &.black_font {
            color: var(--color-light-black) !important;
        }

        .failed {
            border-top: var(--table-borders);
            border-bottom: var(--table-borders);
            background: #FFEAEA;
        }
    }

    th {
        height: 40px;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--color-dark-grey);

        &.blue {
            color: var(--color-light-blue);
        }
    }

    .ghost-table-row {
        td {
            @extend %light-ghost-animation;
        }
    }

    td {
        @extend %text-ellipsis;
        height: 60px;
        color: var(--color-light-black);
    }

    &-pagination-controls {
        display: flex;
        width: fit-content;
        max-width: 50%;
        margin: auto;
        padding-top: 15px;

        button {
            border-radius: 50%;
            height: 36px;
            min-width: 36px;
            background-color: transparent;
            font-size: 0.875rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--color-light-black);

            &.active {
                background-color: var(--color-light-blue);
                color: var(--color-white);
            }
        }
    }

    .placeholder {
        margin: 50px auto 0 auto;
        width: 100%;
        text-align: center;
        color: var(--color-1);
        position: sticky;
        left: 0;
    }


    tfoot td:first-child {
        max-width: 340px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 3;
    }


    tfoot td:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
        background-color: #fff;
    }
}