.tooltip-child-stack {
 
    .tooltip{
        svg {
            width: 26px;
            height: 26px;
        }
        &__title {
            font-size: 1rem;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 15px;
        }
        &__description {
            font-size: 1rem;
            line-height: 135%;

            ol {
                padding-left: 1.2rem;
            }
        }
    }
}