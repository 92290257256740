.privacy-policies-container {
    width: 100%;
    min-width: 100%;
    padding: 0 !important;


    .header-content-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-content {
        height: calc(100% - 90px);
        overflow: auto;

        a {
            color: #4285f4;
        }

    }

    .visit-website-button {
        background-color: var(--color-black);
        color: var(--color-white);
        height: 50px;

        &:hover {
            background-color: var(--color-black);
            box-shadow: none;
            opacity: 0.8;
        }
    }

}