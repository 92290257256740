.text-18-bold {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 24px;
}

.text-16-bold {
    font-weight: 700;
    font-size: 1rem;
}

.text-16-light {
    color: var(--color-1);
    font-size: 1rem;
    line-height: 24px;
}

.break-line {
    height: 1px;
    background: var(--color-12);
    flex-grow: 1;
}