.default-policies {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px #D9D9D9 solid;
    text-align: center;
    padding: 15px;
    box-shadow: none !important;

    label {
        font-weight: 400;
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }

    &-container {
        &-criteria {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: space-evenly;


            .high {
                border-radius: 5px;
                border: 1px solid #539966;

            }

            .moderate {
                border-radius: 5px;
                border: 1.333px solid #FFE8A4;

            }

            .low {
                border-radius: 5px;
                border: 1px solid #A0BEFF;

            }

            &-item {
                border: 1px solid #d9d9d9;
                border-radius: 12px;
                padding: 10px;
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    align-items: center;
                    display: flex;
                    font-weight: 400;
                    gap: 10px;
                    padding: 0 0 10px;
                    text-transform: capitalize;
                    font-size: 13px;
                    justify-content: center;
                }

                h4 {
                    font-weight: 200;
                    font-size: 13px;

                    span {
                        color: var(--Base-Black, #28F);
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }
            }
        }
    }

}